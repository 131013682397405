import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


import './App.css';
import Header from './components/header';
import Home from './components/home';
import FLGS from './components/flgs';

function App() {
    return (
        <div id="root">
            <Router>
                <Header/>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/flgs" component={FLGS} />
                </Switch>
                <footer>
                    Brought to you by <a target="_blank" rel="noreferrer" href="https://www.levelupdice.net">Level Up Dice</a>
                </footer>
            </Router>

        </div>
);
}

export default App;
