const dice =[
    {
        "id": 1,
        "type": "regular",
        "design": "Riku",
        "stone": "Brown Snowflake Jasper",
        "rarity": "common"
    },
    {
        "id": 1,
        "type": "foil",
        "design": "Riku",
        "stone": "Brown Snowflake Jasper",
        "rarity": "common"
    },
    {
        "id": 2,
        "type": "regular",
        "design": "Era",
        "stone": "Lapis Lazuli",
        "rarity": "common"
    },
    {
        "id": 2,
        "type": "foil",
        "design": "Era",
        "stone": "Lapis Lazuli",
        "rarity": "common"
    },
    {
        "id": 3,
        "type": "regular",
        "design": "Agni",
        "stone": "Red Granite",
        "rarity": "common"
    },
    {
        "id": 3,
        "type": "foil",
        "design": "Agni",
        "stone": "Red Granite",
        "rarity": "common"
    },
    {
        "id": 4,
        "type": "regular",
        "design": "Meri",
        "stone": "Blue Bahia Granite",
        "rarity": "common"
    },
    {
        "id": 4,
        "type": "foil",
        "design": "Meri",
        "stone": "Blue Bahia Granite",
        "rarity": "common"
    },
    {
        "id": 5,
        "type": "regular",
        "design": "Patolli",
        "stone": "Green Cats Eye",
        "rarity": "common"
    },
    {
        "id": 5,
        "type": "foil",
        "design": "Patolli",
        "stone": "Green Cats Eye",
        "rarity": "common"
    },
    {
        "id": 6,
        "type": "regular",
        "design": "ziin-gi",
        "stone": "Blue Cats Eye",
        "rarity": "common"
    },
    {
        "id": 6,
        "type": "foil",
        "design": "ziin-gi",
        "stone": "Blue Cats Eye",
        "rarity": "common"
    },
    {
        "id": 7,
        "type": "regular",
        "design": "ζάρια",
        "stone": "Howlite",
        "rarity": "common"
    },
    {
        "id": 7,
        "type": "foil",
        "design": "ζάρια",
        "stone": "Howlite",
        "rarity": "common"
    },
    {
        "id": 8,
        "type": "regular",
        "design": "Blinky",
        "stone": "Citrine",
        "rarity": "common"
    },
    {
        "id": 8,
        "type": "foil",
        "design": "Blinky",
        "stone": "Citrine",
        "rarity": "common"
    },
    {
        "id": 9,
        "type": "regular",
        "design": "Taraxacum",
        "stone": "Kambaba Jasper",
        "rarity": "common"
    },
    {
        "id": 9,
        "type": "foil",
        "design": "Taraxacum",
        "stone": "Kambaba Jasper",
        "rarity": "common"
    },
    {
        "id": 10,
        "type": "regular",
        "design": "Nymphaea",
        "stone": "Rose Quartz",
        "rarity": "common"
    },
    {
        "id": 10,
        "type": "foil",
        "design": "Nymphaea",
        "stone": "Rose Quartz",
        "rarity": "common"
    },
    {
        "id": 11,
        "type": "regular",
        "design": "Weaver",
        "stone": "Pink Cats Eye",
        "rarity": "common"
    },
    {
        "id": 11,
        "type": "foil",
        "design": "Weaver",
        "stone": "Pink Cats Eye",
        "rarity": "common"
    },
    {
        "id": 12,
        "type": "regular",
        "design": "Duckie",
        "stone": "Yellow K9 Glass",
        "rarity": "common"
    },
    {
        "id": 12,
        "type": "foil",
        "design": "Duckie",
        "stone": "Yellow K9 Glass",
        "rarity": "common"
    },
    {
        "id": 13,
        "type": "regular",
        "design": "Conjunction",
        "stone": "Blue Sandstone",
        "rarity": "common"
    },
    {
        "id": 13,
        "type": "foil",
        "design": "Conjunction",
        "stone": "Blue Sandstone",
        "rarity": "common"
    },
    {
        "id": 14,
        "type": "regular",
        "design": "Tua",
        "stone": "Malachite",
        "rarity": "common"
    },
    {
        "id": 14,
        "type": "foil",
        "design": "Tua",
        "stone": "Malachite",
        "rarity": "common"
    },
    {
        "id": 15,
        "type": "regular",
        "design": "Iamthelaw",
        "stone": "Mahogany Obsidian",
        "rarity": "common"
    },
    {
        "id": 15,
        "type": "foil",
        "design": "Iamthelaw",
        "stone": "Mahogany Obsidian",
        "rarity": "common"
    },
    {
        "id": 16,
        "type": "regular",
        "design": "Mesozoic",
        "stone": "Eramosa Marble",
        "rarity": "common"
    },
    {
        "id": 16,
        "type": "foil",
        "design": "Mesozoic",
        "stone": "Eramosa Marble",
        "rarity": "common"
    },
    {
        "id": 17,
        "type": "regular",
        "design": "Nellyday",
        "stone": "Black Cats Eye",
        "rarity": "common"
    },
    {
        "id": 17,
        "type": "foil",
        "design": "Nellyday",
        "stone": "Black Cats Eye",
        "rarity": "common"
    },
    {
        "id": 18,
        "type": "regular",
        "design": "Kismet",
        "stone": "Indian Agate",
        "rarity": "common"
    },
    {
        "id": 18,
        "type": "foil",
        "design": "Kismet",
        "stone": "Indian Agate",
        "rarity": "common"
    },
    {
        "id": 19,
        "type": "regular",
        "design": "POP",
        "stone": "Light Blue K9 Glass",
        "rarity": "common"
    },
    {
        "id": 19,
        "type": "foil",
        "design": "POP",
        "stone": "Light Blue K9 Glass",
        "rarity": "common"
    },
    {
        "id": 20,
        "type": "regular",
        "design": "熊猫",
        "stone": "Obsidian",
        "rarity": "common"
    },
    {
        "id": 20,
        "type": "foil",
        "design": "熊猫",
        "stone": "Obsidian",
        "rarity": "common"
    },
    {
        "id": 21,
        "type": "regular",
        "design": "Librum",
        "stone": "Ivory Jade",
        "rarity": "common"
    },
    {
        "id": 21,
        "type": "foil",
        "design": "Librum",
        "stone": "Ivory Jade",
        "rarity": "common"
    },
    {
        "id": 22,
        "type": "regular",
        "design": "Aero",
        "stone": "Picture Jasper",
        "rarity": "common"
    },
    {
        "id": 22,
        "type": "foil",
        "design": "Aero",
        "stone": "Picture Jasper",
        "rarity": "common"
    },
    {
        "id": 23,
        "type": "regular",
        "design": "Motorvate",
        "stone": "Obsidian",
        "rarity": "common"
    },
    {
        "id": 23,
        "type": "foil",
        "design": "Motorvate",
        "stone": "Obsidian",
        "rarity": "common"
    },
    {
        "id": 24,
        "type": "regular",
        "design": "R",
        "stone": "Bloodstone",
        "rarity": "common"
    },
    {
        "id": 24,
        "type": "foil",
        "design": "R",
        "stone": "Bloodstone",
        "rarity": "common"
    },
    {
        "id": 25,
        "type": "regular",
        "design": "uoᴉʇdǝɔ-ǝɔᴉp",
        "stone": "Egyptian Marble",
        "rarity": "common"
    },
    {
        "id": 25,
        "type": "foil",
        "design": "uoᴉʇdǝɔ-ǝɔᴉp",
        "stone": "Egyptian Marble",
        "rarity": "common"
    },
    {
        "id": 26,
        "type": "regular",
        "design": "♡",
        "stone": "Red K9 Glass",
        "rarity": "uncommon"
    },
    {
        "id": 26,
        "type": "foil",
        "design": "♡",
        "stone": "Red K9 Glass",
        "rarity": "uncommon"
    },
    {
        "id": 27,
        "type": "regular",
        "design": "♣",
        "stone": "Black Cats Eye",
        "rarity": "uncommon"
    },
    {
        "id": 27,
        "type": "foil",
        "design": "♣",
        "stone": "Black Cats Eye",
        "rarity": "uncommon"
    },
    {
        "id": 28,
        "type": "regular",
        "design": "♠",
        "stone": "Black Cats Eye",
        "rarity": "uncommon"
    },
    {
        "id": 28,
        "type": "foil",
        "design": "♠",
        "stone": "Black Cats Eye",
        "rarity": "uncommon"
    },
    {
        "id": 29,
        "type": "regular",
        "design": "♢",
        "stone": "Red K9 Glass",
        "rarity": "uncommon"
    },
    {
        "id": 29,
        "type": "foil",
        "design": "♢",
        "stone": "Red K9 Glass",
        "rarity": "uncommon"
    },
    {
        "id": 30,
        "type": "regular",
        "design": "Vortex",
        "stone": "Obsidian",
        "rarity": "uncommon"
    },
    {
        "id": 30,
        "type": "foil",
        "design": "Vortex",
        "stone": "Obsidian",
        "rarity": "uncommon"
    },
    {
        "id": 31,
        "type": "regular",
        "design": "DeDuce",
        "stone": "Red Jasper",
        "rarity": "uncommon"
    },
    {
        "id": 31,
        "type": "foil",
        "design": "DeDuce",
        "stone": "Red Jasper",
        "rarity": "uncommon"
    },
    {
        "id": 32,
        "type": "regular",
        "design": "Icing",
        "stone": "Opalite",
        "rarity": "uncommon"
    },
    {
        "id": 32,
        "type": "foil",
        "design": "Icing",
        "stone": "Opalite",
        "rarity": "uncommon"
    },
    {
        "id": 33,
        "type": "regular",
        "design": "Arlecchino",
        "stone": "Blue Turquoise",
        "rarity": "uncommon"
    },
    {
        "id": 33,
        "type": "foil",
        "design": "Arlecchino",
        "stone": "Blue Turquoise",
        "rarity": "uncommon"
    },
    {
        "id": 34,
        "type": "regular",
        "design": "Tux",
        "stone": "Black Network Agate",
        "rarity": "uncommon"
    },
    {
        "id": 34,
        "type": "foil",
        "design": "Tux",
        "stone": "Black Network Agate",
        "rarity": "uncommon"
    },
    {
        "id": 35,
        "type": "regular",
        "design": "o.O",
        "stone": "Green Turquoise",
        "rarity": "uncommon"
    },
    {
        "id": 35,
        "type": "foil",
        "design": "o.O",
        "stone": "Green Turquoise",
        "rarity": "uncommon"
    },
    {
        "id": 36,
        "type": "regular",
        "design": "A La Mode",
        "stone": "Smokey Grey Cats Eye",
        "rarity": "uncommon"
    },
    {
        "id": 36,
        "type": "foil",
        "design": "A La Mode",
        "stone": "Smokey Grey Cats Eye",
        "rarity": "uncommon"
    },
    {
        "id": 37,
        "type": "regular",
        "design": "Mix It",
        "stone": "Red Adventurine",
        "rarity": "uncommon"
    },
    {
        "id": 37,
        "type": "foil",
        "design": "Mix It",
        "stone": "Red Adventurine",
        "rarity": "uncommon"
    },
    {
        "id": 38,
        "type": "regular",
        "design": "Om nom nom",
        "stone": "Tigers Eye",
        "rarity": "uncommon"
    },
    {
        "id": 38,
        "type": "foil",
        "design": "Om nom nom",
        "stone": "Tigers Eye",
        "rarity": "uncommon"
    },
    {
        "id": 39,
        "type": "regular",
        "design": "⏃⌰⟟⟒⋏",
        "stone": "Blue Sandstone",
        "rarity": "uncommon"
    },
    {
        "id": 39,
        "type": "foil",
        "design": "⏃⌰⟟⟒⋏",
        "stone": "Blue Sandstone",
        "rarity": "uncommon"
    },
    {
        "id": 40,
        "type": "regular",
        "design": "Illumination",
        "stone": "Holographic Glass ",
        "rarity": "rare"
    },
    {
        "id": 40,
        "type": "foil",
        "design": "Illumination",
        "stone": "Holographic Glass ",
        "rarity": "rare"
    },
    {
        "id": 41,
        "type": "regular",
        "design": "B sharp",
        "stone": "Red Tigers Eye",
        "rarity": "rare"
    },
    {
        "id": 41,
        "type": "foil",
        "design": "B sharp",
        "stone": "Red Tigers Eye",
        "rarity": "rare"
    },
    {
        "id": 42,
        "type": "regular",
        "design": "Diviniation",
        "stone": "Holographic Glass ",
        "rarity": "rare"
    },
    {
        "id": 42,
        "type": "foil",
        "design": "Diviniation",
        "stone": "Holographic Glass ",
        "rarity": "rare"
    },
    {
        "id": 43,
        "type": "regular",
        "design": "Intersection",
        "stone": "Dark Blue K9 Glass ",
        "rarity": "rare"
    },
    {
        "id": 43,
        "type": "foil",
        "design": "Intersection",
        "stone": "Dark Blue K9 Glass ",
        "rarity": "rare"
    },
    {
        "id": 44,
        "type": "regular",
        "design": "Concentric",
        "stone": "Red K9 Glass",
        "rarity": "rare"
    },
    {
        "id": 44,
        "type": "foil",
        "design": "Concentric",
        "stone": "Red K9 Glass",
        "rarity": "rare"
    },
    {
        "id": 45,
        "type": "regular",
        "design": "Deadly Mob",
        "stone": "Red/Yellow/Blue Turquoise",
        "rarity": "rare"
    },
    {
        "id": 45,
        "type": "foil",
        "design": "Deadly Mob",
        "stone": "Red/Yellow/Blue Turquoise",
        "rarity": "rare"
    },
    {
        "id": 46,
        "type": "regular",
        "design": "Sugar Rush",
        "stone": "Yellow/Green/Purple Turquoise",
        "rarity": "rare"
    },
    {
        "id": 46,
        "type": "foil",
        "design": "Sugar Rush",
        "stone": "Yellow/Green/Purple Turquoise",
        "rarity": "rare"
    },
    {
        "id": 47,
        "type": "regular",
        "design": "nishikigoi",
        "stone": "Melody (Harmonies) ",
        "rarity": "rare"
    },
    {
        "id": 47,
        "type": "foil",
        "design": "nishikigoi",
        "stone": "Melody (Harmonies) ",
        "rarity": "rare"
    },
    {
        "id": 48,
        "type": "regular",
        "design": "sloop",
        "stone": "Syncopation (Harmonies)",
        "rarity": "rare"
    },
    {
        "id": 48,
        "type": "foil",
        "design": "sloop",
        "stone": "Syncopation (Harmonies)",
        "rarity": "rare"
    },
    {
        "id": 49,
        "type": "regular",
        "design": "JumpPupper",
        "stone": "Sonata (Harmonies)",
        "rarity": "rare"
    },
    {
        "id": 49,
        "type": "foil",
        "design": "JumpPupper",
        "stone": "Sonata (Harmonies)",
        "rarity": "rare"
    },
    {
        "id": 50,
        "type": "regular",
        "design": "Good-Bye",
        "stone": "Purple Cats Eye",
        "rarity": "rare"
    },
    {
        "id": 50,
        "type": "foil",
        "design": "Good-Bye",
        "stone": "Purple Cats Eye",
        "rarity": "rare"
    }
]


export default dice;
