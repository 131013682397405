import Links from './links';
import DiceContainer from "./dice-container";
import secret1 from '../assets/img/fullres/gbb3-51.jpg';
import secret2 from '../assets/img/fullres/gbb3-52.jpg';
import secret3 from '../assets/img/fullres/gbb3-53.jpg';

export default function Home() {


    return (
        <div className="content">
            <div className="intro">
                <h2>Level Up Dice is excited to announce our most stunning retail partnership of 2022
                    <br/> <span className="bold">The Glyphic Blind Bag Series 3!</span></h2>
                <Links/>
                <br/>
                <p>Glyphic Blind Bag Series 3 includes <span className="bold">50 new designs</span> (with
                    additional chase foils of each)!</p>

                <p>Browse below to see all the designs of Series 3. You can <span
                    className="bold">use the buttons to sort</span> by rarity as well as if you own it.</p>

                <p><span className="bold">Add to your collection</span> as you get them and our site will
                    use the magic
                    of cookies to keep track for when you come back!</p>
                <p>All foil images are provided by other adventurers that find them in the wild. If you come across 
                    a rare foil on your adventures that we dont have a photo of displayed here, snap a photo of the 6th face and send us an email at <a href="mailto:info@levelupdice.net">info@levelupdice.net</a>.</p>

            </div>
            <div className="interaction-container">
                <div className="rarity-container">
                    <h3>Each pack has a</h3>
                    <div>
                        <div><h4>55<a className="secret"
                            data-fancybox="secret-gallery-1" href={secret1}
                            data-caption="&#9679; 51 Motorvate (Alt)">%</a> chance</h4><p>of being <b>common</b></p></div>
                        <div><h4>35<a className="secret"
                            data-fancybox="secret-gallery-2" href={secret2}
                            data-caption="&#9679; 52 Vortex (Alt)">%</a> chance</h4><p>of being <b>uncommon</b></p></div>
                        <div><h4>10<a className="secret"
                            data-fancybox="secret-gallery-3" href={secret3}
                            data-caption="&#9679; 53 Goodbye (Alt)">%</a> chance</h4><p>of being <b>rare</b></p></div>
                    </div>
                </div>
                {/*<div className="progress-container">*/}
                {/*    <h3>Collection</h3>*/}
                {/*    <h1>...</h1>*/}
                {/*</div>*/}
            </div>
            <hr/>
            <DiceContainer/>
        </div>

    )
}


