import imgHeader from "../assets/img/header.png";

export default function Header() {


    return (
        <header className="App-header">
            <div className="breakout">
                <a target="_blank" rel="noreferrer" href="http://gbb2.levelupdice.net">GBB Series 2 ></a>
            </div>
            <img className="img-header" src={imgHeader} alt="Glyphic Blind Bag Series 3"
                 title="Glyphic Blind Bag Series 3"/>
        </header>
    )
}
